import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "./reduxHooks";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import { useTokenContract } from "./useTokenContract";
import { CONTRACT_ADDRESS_VAULT } from "../constants/vaultContract";
import { useWeb3React } from "@web3-react/core";
import { MaxUint256 } from "@ethersproject/constants";
import { calculateGasMargin } from "../utils/calculateGasMargin";

export const useApproveCallback = (amountToApprove: any) => {
  const [prevApprovedAmount, setPrevApprovedAmount] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { account, connector } = useWeb3React();
  const selectedLpToken = useAppSelector(
      (state) => state.selectLpTokenReducer.selectedLpToken
  );

  const contract = useTokenContract(selectedLpToken.contractAddress, connector);

  const getApprovedAmount = async () => {
    const currentContract = await contract;
    if (account) {
      const approvedAmount = await currentContract
          .allowance(account, CONTRACT_ADDRESS_VAULT)
          .then((res: any) => ethers.utils.formatUnits(res.toString(), 18));
      setPrevApprovedAmount(approvedAmount);
    }

  };

  useEffect(() => {
    if (selectedLpToken.contractAddress) {
      getApprovedAmount();
    }
  }, [selectedLpToken, amountToApprove, isLoading]);


  const approved = useMemo(() => {
    if(+amountToApprove === 0) {
      return false
    } else {
      const prevAllowedAmountBigNum = new BigNumber(prevApprovedAmount);
      const amountToApproveBigNum = new BigNumber(amountToApprove);
      return amountToApproveBigNum.isLessThanOrEqualTo(prevAllowedAmountBigNum);
    }

  }, [amountToApprove, selectedLpToken, prevApprovedAmount, isLoading]);

  const approve = useCallback(async (): Promise<void> => {
    const currentContract = await contract;
    if (approved || !selectedLpToken.contractAddress) {
      return;
    }
    try {
      setIsLoading(true);
      if (!selectedLpToken.amount) {
        console.error("no token");
        return;
      }

      let useExact = false;
      const estimatedGas = await currentContract.estimateGas
          .approve(CONTRACT_ADDRESS_VAULT, MaxUint256)
          .catch(() => {
            // general fallback for tokens who restrict approval amounts
            useExact = true;
            return currentContract.estimateGas.approve(
                CONTRACT_ADDRESS_VAULT,
                ethers.utils.parseUnits(amountToApprove, 18)
            );
          });

      await currentContract
          .approve(
              CONTRACT_ADDRESS_VAULT, useExact ?
              ethers.utils.parseUnits(amountToApprove, 18) : MaxUint256,
              {
                gasLimit: calculateGasMargin(estimatedGas),
              }
          )
          .then(() => {
            setTimeout(() => {
              setIsLoading(false);
            }, 20000);
          })
          .catch((e: any) => {
            setIsLoading(false);
          });
    } catch (e) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [amountToApprove, account]);

  return [approved, approve, isLoading];
};
