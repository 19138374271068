import { ethers } from "ethers";
import {
  DISTRIBUTOR_CONTRACT_ABI,
  DISTRIBUTOR_CONTRACT_ADDRESS,
} from "../constants/distributorContract";
import { fetchClaimInfo } from "../api/fetchClaimInfo";
import { configMainNet } from "../connectors/config";
import {useState} from "react";
import {useLpTokensContract} from "./useLpTokenContract";

export const useFetchClaimAll = (account: any, connector: any,) => {
  const [isClaiming, setIsClaiming] = useState<boolean>(false);
    const currentContract = useLpTokensContract(DISTRIBUTOR_CONTRACT_ADDRESS, DISTRIBUTOR_CONTRACT_ABI, connector);
  // @ts-ignore
  if (!account) {
    return [() => {}, false];
  } else {
      const data =  fetchClaimInfo(account);
      const claim = async () => {
          const dataRes = await data;
          const distributorContract = await currentContract;
          if (dataRes) {
              setIsClaiming(true)
              distributorContract
                  ?.claim(dataRes.index, dataRes.address, dataRes.amount, dataRes.proofs)
                  .then(() => {
                      setTimeout(() => {
                          setIsClaiming(false);
                      }, 20000)
                  }).catch(() => {
                  setIsClaiming(false)
              })

          } else {
              () => {};
          }
      }
      return [claim, isClaiming];
  }
};
