export const getPriceInUsd = (
  balance: number,
  totalSupply: number,
  token0balance: number,
  token0InUsd: number,
  token1Balance: number,
  token1InUsd: number
): number => {
  return (
    (balance / totalSupply) *
    (token0balance * token0InUsd + token1Balance * token1InUsd)
  ) || 0;
};
