import { combineReducers, configureStore } from "@reduxjs/toolkit";
import modalSlices from "../slices/modalSlices";
import selectLpTokenReducer from "../slices/selectLpTokenSlice";

export const rootReducer = combineReducers({
  modalSlices,
  selectLpTokenReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
