import { useMemo } from 'react';
import { useMulticall } from './useMulticall';
import {
  CONTRACT_ABI_VAULT,
  CONTRACT_ADDRESS_VAULT,
} from 'constants/vaultContract';
import { useLpTokensContract as useVaultContract } from './useLpTokenContract';
import { TOKENS_PAIRS } from 'constants/tokensPairs';
import BigNumber from 'bignumber.js';
import {useAppSelector} from "./reduxHooks";

export interface CallResponse {
  address: string,
  data: [amount: BigNumber, withdraw: Boolean];
}

export async function useLocksMulticallData(
  account: string | null | undefined, connector: any
): Promise<Array<CallResponse>> {


  const options = [0, 1, 2];
  const contract = useVaultContract(CONTRACT_ADDRESS_VAULT, CONTRACT_ABI_VAULT, connector);
  const multicallContract = useMulticall();
  const callData = TOKENS_PAIRS.map((pair) => {
    return { account, lpTokenAddress: pair.liquidityToken.address };
  });

  const isLocked = useAppSelector(
      (state) => state.selectLpTokenReducer.isLocked
  );
  const isUnlocked = useAppSelector(state => state.selectLpTokenReducer.isUnlocked)


  const multicallResults = useMemo(async () => {
    const currentContract = await contract;
    const data = options.map((opt) => {
      return callData.map((data) => {
        return {
          address: CONTRACT_ADDRESS_VAULT,
          callData: currentContract.interface.encodeFunctionData(
            'locks',
            [data.account, data.lpTokenAddress, opt] ?? []
          ),
        };
      });
    });

      const res = Promise.all(
      data.map(async (call, index) => {
        const awaitedMultiContract = await multicallContract
        let resultsBlockNumber, returnData;
        try {
          [resultsBlockNumber, returnData] = await awaitedMultiContract.aggregate(
            call.map((obj: any) => [obj.address, obj.callData])
          );
        } catch (error) {
          console.debug(
            'Failed to fetch chunk ' + resultsBlockNumber + ' inside retry',
            error
          );
          throw error;
        }
        if (returnData) {
          const result = returnData.reduce(
            (res: any[], aggregateItemResult: any, i: number) => {
              const lpToken = TOKENS_PAIRS[i].liquidityToken;
              const data = currentContract.interface.decodeFunctionResult(
                'locks',
                aggregateItemResult
              );
              res[i] = {
                address: lpToken.address,
                data,
              };
              return res;
            },
            []
          );
          return result;
        }
        return undefined;
      })
    );
    return res;
  }, [isLocked, isUnlocked,  account]);

  return multicallResults;
}
