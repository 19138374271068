import { RouteNames } from "./routeNames";
import { FooterNavTypes } from "../types/footerNavTypes";

export const setExFooterNavs: FooterNavTypes = [
  { link: RouteNames.MAIN_PAGE, linkLabel: "Main" },
  { link: "https://step.exchange/memorandum", linkLabel: "Memorandum" },
];

export const socialsFooterNavs: FooterNavTypes = [
  { link: "https://t.me/stepappchat", linkLabel: "Telegram" },
  { link: "https://discord.com/invite/stepappdc", linkLabel: "Discord" },
  { link: "#", linkLabel: "Medium" },
  { link: "https://twitter.com/stepapp_", linkLabel: "Twitter" },
];

export const projectsFooterNav: FooterNavTypes = [
  { link: "https://step.network/", linkLabel: "Step network" },
  { link: "http://step.app/", linkLabel: "Step app" },
  { link: "https://staking.step.app/", linkLabel: "Staking" },
  { link: "https://steplaunch.org/", linkLabel: "Launchpad" },
];
