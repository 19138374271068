import {useEffect, useState} from "react";
import { TOKENS_PAIRS } from "../constants/tokensPairs";
import { useTokenContract } from "./useTokenContract";
import { useWeb3React } from "@web3-react/core";
import { formatToDecimals } from "../utils/formatToDecimals";
import { CONTRACT_ADDRESS_VAULT } from "../constants/vaultContract";

import { getPriceInUsd } from "../utils/getPriceInUsd";
import { fethUSD } from "../utils/fetchUSD";


export const useAllLpTokensList = () => {
  const [listOfLpTkPairs, setListOfLpTkPairs] = useState<any>([]);

  const { account, connector } = useWeb3React();
  const threeMinDelay = 3 * 60 * 1000;
  const getLpTkList = async () => {
      const allLpTokens = await Promise.all(
      TOKENS_PAIRS?.map(async (item) => {
        const pairContract = await useTokenContract(item.liquidityToken.address, connector);
        const token0Contract = await useTokenContract(item.tokens[0].address, connector);
        const token1Contract = await useTokenContract(item.tokens[1].address, connector);

        const { token0InUsd, token1InUsd } = await fethUSD(
          item.tokens[0].coingeckoId,
          item.tokens[1].coingeckoId
        );

        const balanceAll: any = formatToDecimals(
          await pairContract?.balanceOf(CONTRACT_ADDRESS_VAULT),
          item.liquidityToken.decimals
        );

        const userBalance: any = formatToDecimals(
              await pairContract?.balanceOf(account),
              item.liquidityToken.decimals
            );
        const totalSupply: any = formatToDecimals(
          await pairContract?.totalSupply(),
          item.liquidityToken.decimals
        );
        const token0balance: any = formatToDecimals(
          await token0Contract?.balanceOf(item.liquidityToken.address),
          item.tokens[0].decimals
        );
        const token1balance: any = formatToDecimals(
          await token1Contract?.balanceOf(item.liquidityToken.address),
          item.tokens[1].decimals
        );

        const totalSupplyInUsd = getPriceInUsd(
          balanceAll,
          totalSupply,
          token0balance,
          token0InUsd,
          token1balance,
          token1InUsd
        );

        const userBalanceInUsd = getPriceInUsd(
          userBalance,
          totalSupply,
          token0balance,
          token0InUsd,
          token1balance,
          token1InUsd
        );

        return {
          amount: userBalance || 0,
          amountInUsd: (!token0InUsd || !token1InUsd)
              ? "" : (userBalanceInUsd || 0),
          contractAddress: item.liquidityToken.address,
          totalSupply: totalSupply,
          balanceAll,
          totalSupplyInUsd:
            (!token0InUsd || !token1InUsd ) ? "" : (totalSupplyInUsd),

          tokenFrom: {
            name: item.tokens[0].name,
            address: item.tokens[0].address,
            decimals: item.tokens[0].decimals,
            logoUri: item.tokens[0].logoUri,
            coingeckoId: item.tokens[0].coingeckoId,
          },
          tokenTo: {
            name: item.tokens[1].name,
            address: item.tokens[1].address,
            decimals: item.tokens[1].decimals,
            logoUri: item.tokens[1].logoUri,
            coingeckoId: item.tokens[1].coingeckoId,
          },
        };
      })
    );
    setListOfLpTkPairs(allLpTokens);
  };

  const tvl = listOfLpTkPairs?.reduce((accumulator: any, current: any) => accumulator + current.totalSupplyInUsd, 0);
  useEffect(() => {
    setInterval( async () => {
      if (account) {
        await getLpTkList()
      }
    }, threeMinDelay);
  }, [account]);


  useEffect(() => {
    if (account) {
      getLpTkList();
    }
  }, [account]);

  return [listOfLpTkPairs, tvl];
};
