import { ethers } from 'ethers';
import MULTICALL_ABI from '../constants/multicall_abi.json';
import {useWeb3React} from "@web3-react/core";
import {configMainNet} from "../connectors/config";

export const useMulticall = async () => {
  const { connector } = useWeb3React();
  if (connector) {
    const web3Provider = await connector.getProvider();
    const provider = new ethers.providers.Web3Provider(web3Provider)
    const signer = provider.getSigner()
    return new ethers.Contract(
        '0x176CcFFbAB792Aaa0da7C430FE20a7106d969f66',
        MULTICALL_ABI,
        signer
    );
  } else {
    const RPC = configMainNet.rpc;
    const provider = new ethers.providers.JsonRpcProvider(RPC, configMainNet.defaultChain);
    /*
            const signer = provider.getSigner();
    */
    return new ethers.Contract(
        '0x176CcFFbAB792Aaa0da7C430FE20a7106d969f66',
        MULTICALL_ABI,
        provider
    );
  }
};
