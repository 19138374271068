import React, { useEffect, useState, Suspense } from "react";
import styles from "./styles.module.scss";
import ChooseLpToken from "../../components/ChooseLpToken";
import MyPositionTokens from "../../components/MyPositionTokens";
import { useWeb3React } from "@web3-react/core";
import { useActions } from "../../hooks/useActions";
import ClaimRewards from "../../components/ClaimRewards";
import {useSnapShotDate} from "../../hooks/useSnapShotDate";
import FirstPhaseInfo from "../../components/FirstPhaseInfo";

const LockdropGuide = React.lazy(() => import('../../components/LockDropGuide'));

const MainPage = () => {
  const { getMyLpTokensThunk } = useActions();
  const [isSnapshopDate, setIsSnapshopDate] = useState<boolean>(false);
  const [snapshopDate, setSnapshopDate] = useState<Date>(new Date("Fri Sep 02 2022 15:00:00 GMT+0300"));
  const { account, connector } = useWeb3React();

  useEffect(() => {
    account && getMyLpTokensThunk({account, connector});
  }, [account]);

  const checkSnapDate = async () => {
    const snapDate = await useSnapShotDate(connector);
    const nowDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours()
    );
    setSnapshopDate(snapDate);

    if (+nowDate >= +snapDate) {
      setIsSnapshopDate(true);
    } else {
      setIsSnapshopDate(false);
    }
  }

  useEffect(() => {
     checkSnapDate()
  }, []);

  return (
    <main className={styles.mainPage}>
      <div className={styles.positionsWrapper}>
        <ClaimRewards />
        {account && <MyPositionTokens />}
      </div>
        <div className={styles.selectTokensWrapper}>
          <>
          <h2 className={styles.mainLabel}>
              <FirstPhaseInfo snapshopDate={snapshopDate} isSnapshopDate={isSnapshopDate}/>
              <div className={styles.mainSubLabel}>
                Select how much lp tokens you want to lock and what term you
                would like to lock it <br className={styles.mainSubLabelBr} />{" "}
                to increase rewards
              </div>
          </h2>
          </>
          <ChooseLpToken isSnapshopDate={isSnapshopDate} />
        </div>
      <Suspense fallback={<></>}>
        <LockdropGuide/>
      </Suspense>
    </main>
  );
};

export default React.memo(MainPage);
