import React, { useEffect, useState} from "react";
import styles from "./styles.module.scss";
import { numWithCommas } from "utils/validationSchemes";
import { TOKENS_PAIRS } from "constants/tokensPairs";
import { useLpTokensContract } from "../../hooks/useLpTokenContract";
import {
  CONTRACT_ABI_VAULT,
  CONTRACT_ADDRESS_VAULT,
} from "../../constants/vaultContract";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useTokenContract } from "../../hooks/useTokenContract";
import { formatToDecimals } from "../../utils/formatToDecimals";
import { fethUSD } from "../../utils/fetchUSD";
import { getPriceInUsd } from "../../utils/getPriceInUsd";
import cx from "classnames";
import Tooltip from "@mui/material/Tooltip";
import { getEndDate } from "../../utils/getEndDate";
import {OPTION_TO_MONTHS} from "../../constants/projectCompilers";
import { fetchRewardsByPool } from "api/fetchRewardsByPool";
import {useLocksMulticallData} from "../../hooks/useMulticallData";
import { useActions } from "hooks/useActions";
import {checkDistributionDate} from "../../utils/checkDistributionDate";
import {noExponents} from "../../utils/noExponents";



const PositionTokens = () => {
  const [renderPosArr, setRenderPosArr] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUnlocking, setIsUnlocking] = useState<boolean>(false);
  const [unlockingIndex, setUnlockingIndex] = useState<null | number>(null);
  const threeMinDelay = 3 * 60 * 1000;
  const isLocked = useAppSelector(
    (state) => state.selectLpTokenReducer.isLocked
  );
  const isUnlocked = useAppSelector(state => state.selectLpTokenReducer.isUnlocked)
  const { account, connector } = useWeb3React();
  const contract = useLpTokensContract(
    CONTRACT_ADDRESS_VAULT,
    CONTRACT_ABI_VAULT,
      connector
  );
  const {handleIsUnlocked} = useActions();
  const locks = useLocksMulticallData(
      account, connector
  );


  const getMyPos = async () => {
    setIsLoading(true);
    setRenderPosArr([]);
    const locksRes = await locks;
    const promiseLocksRes = await Promise.all(
        locksRes.map(async (lockPair: any, index: any) => {
          return lockPair.map(async(lockItem: any) => {
            const pairAddress =  lockItem.address;
            const amount = ethers.utils.formatUnits(lockItem.data[1], 18);
            const tokensPair = TOKENS_PAIRS.find(item => item.liquidityToken.address === pairAddress)

            const pairContract = await useTokenContract(pairAddress, connector);
            // @ts-ignore
            const token0Contract = await useTokenContract(tokensPair?.tokens[0].address, connector);
            // @ts-ignore
            const token1Contract = await useTokenContract(tokensPair?.tokens[1].address, connector);

            const totalSupply: any = tokensPair && formatToDecimals(
                await pairContract?.totalSupply(),
                tokensPair.liquidityToken.decimals
            );

            const token0Balance: any = tokensPair && formatToDecimals(
                await token0Contract?.balanceOf(tokensPair?.liquidityToken.address),
                tokensPair.tokens[0].decimals
            );
            const token1Balance: any = tokensPair && formatToDecimals(
                await token1Contract?.balanceOf(tokensPair?.liquidityToken.address),
                tokensPair.tokens[1].decimals
            );

            const { token0InUsd, token1InUsd } = await fethUSD(
            tokensPair?.tokens[0].coingeckoId, tokensPair?.tokens[1].coingeckoId
            );

            const amountInUsd = getPriceInUsd(
                +amount,
                totalSupply,
                token0Balance,
                token0InUsd,
                token1Balance,
                token1InUsd
            );

          const isSpexShown = checkDistributionDate();

            const rewardsByPair = account && (+amount !==0) && isSpexShown &&  await fetchRewardsByPool(account);

          const rewardsByToken = rewardsByPair ? BigInt(+rewardsByPair.filter((pair: any) => pair.token === tokensPair?.liquidityToken.address).filter((item: any) => item.option === index)[0]?.reward).toString() : "";

          const rewardsByTokenToDecimals = rewardsByToken && ethers.utils.formatUnits(rewardsByToken, 18);

            const {endSnapDate, endSnapDateStringify} = await getEndDate(index, connector);
            const nowDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours());
            const isLockable = +nowDate >= +endSnapDate;
            const resOfLockItem = {
              pairAddress: lockItem.address,
              amount,
              amountInUsd,
              rewardsByToken: isSpexShown ? rewardsByTokenToDecimals : "from 05.09.2022",
              option: index,
              isLockable,
              endSnapDateStringify,
              withdrawn: lockItem.data[0],
              tokenFrom: {
                name: tokensPair?.tokens[0].name,
                address: tokensPair?.tokens[0].address,
                decimals: tokensPair?.tokens[0].decimals,
                logoUri: tokensPair?.tokens[0].logoUri,
                coingeckoId: tokensPair?.tokens[0].coingeckoId,
              },
              tokenTo: {
                name: tokensPair?.tokens[1].name,
                address: tokensPair?.tokens[1].address,
                decimals: tokensPair?.tokens[1].decimals,
                logoUri: tokensPair?.tokens[1].logoUri,
                coingeckoId: tokensPair?.tokens[1].coingeckoId,
              },
            }
            return resOfLockItem
          })
        })
    )
    const renderLocksRes = await Promise.all(promiseLocksRes.flat(1));
    const filteredLocks = renderLocksRes?.filter(lock => +lock.amount !== 0);
    filteredLocks.length > 0 ? setRenderPosArr(filteredLocks.filter(lock => !lock.withdrawn)) : setRenderPosArr([]);
    setIsLoading(false);
  }

  useEffect(() => {
    getMyPos();
  }, [isLocked, account]);

  useEffect(() => {
    const getMyPosInterval = setInterval(async () => {
      await getMyPos()
    }, threeMinDelay)
    return () => {
      clearInterval(getMyPosInterval)
    }
  }, [isLocked]);

  const handleUnlock = async (token: any, i: any) => {
    const currentContract = await contract;
   setIsUnlocking(true);
   setUnlockingIndex(i);
   await currentContract.withdraw(token.pairAddress, +token.option).then(() => {
     setTimeout(() => {
       handleIsUnlocked(!isUnlocked);
     }, 19000);
     setTimeout(() => {
       getMyPos();
       setIsUnlocking(false);
       setUnlockingIndex(null);
     }, 20000);
   }).catch(() => {
     setIsUnlocking(false);
     setUnlockingIndex(null);
   });
  };

  if (account && isLoading) {
    return (<div className={cx(styles.positionTokensWrapper, checkDistributionDate() && account && styles.positionTokensWrapperWide)}><div className={styles.loaderWrapper}><span className={styles.loaderBlack}/></div></div>)
  } else if (account && renderPosArr.length) {
    return (
      <>
        <div className={cx(styles.positionTokensWrapper, checkDistributionDate() && account && styles.positionTokensWrapperWide)}>
          <span className={styles.positionsLabel}>My positions</span>
          <div className={styles.positionTabs}>
            <div className={styles.name}>
              <span className={styles.tabLabel}>Name</span>
            </div>
            <div className={styles.amount}>
              <span className={styles.tabLabel}>Amount</span>
            </div>
            <div className={styles.pool}>
              <span className={styles.tabLabel}>Reward by pool</span>
            </div>
            <div className={styles.endsOf}>
              <span className={styles.tabLabel}>Ends of</span>
            </div>
          </div>
          <ul className={styles.positionListWrapper}>
            {renderPosArr.length > 0 &&
              renderPosArr.map((token, i) => (
                <li className={styles.positionListItem} key={i}>
                  <div className={styles.name}>
                    <div className={styles.tokenLogos}>
                      <img
                        src={token.tokenFrom.logoUri}
                        alt="token logo"
                        className={styles.tokenLogoImgFirst}
                      />
                      <img
                        src={token.tokenTo.logoUri}
                        alt="token logo"
                        className={styles.tokenLogoImgSecond}
                      />
                    </div>
                    <span className={cx(styles.positionListItemText)}>
                      {token.tokenFrom.name}:{token.tokenTo.name}
                    </span>
                  </div>
                  <div className={styles.amount}>
                    <div
                      className={cx(
                        styles.positionListItemText,
                        styles.amountContainer
                      )}
                    >
                      <Tooltip
                        title={numWithCommas(token?.amount.toString())}
                        placement={"top"}
                      >
                        <span className={styles.amountText}>
                          {numWithCommas(
                              token?.amount.toString()
                            )}
                        </span>
                      </Tooltip>
                      {token.amountInUsd && (
                        <>
                          <span className={styles.space}/>
                          (
                          <Tooltip
                            title={
                              "$" + numWithCommas(noExponents(token?.amountInUsd).toString())
                            }
                            placement={"top"}
                          >
                            <span className={styles.amountTextUsd}>
                                {"$" +
                                  numWithCommas(Number(token?.amountInUsd).toFixed(2).toString())}
                            </span>
                          </Tooltip>
                          )
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.pool}>
                     <Tooltip title={`${token.rewardsByToken.toString().toLowerCase().includes("from") ? "" : (token.rewardsByToken || 0) + " SPEX"}`} placement={"top"}>
                      <div
                          className={cx(
                              styles.positionListItemText,
                              styles.poolContainer
                          )}
                      >
                        {token.rewardsByToken.toString().toLowerCase().includes("from") ? token.rewardsByToken.toString() : token?.rewardsByToken ? (Number(token?.rewardsByToken).toFixed(2) + " SPEX") : "0 SPEX"}
                      </div>
                    </Tooltip>
                  </div>
                  <div className={styles.endsOf}>
                    <Tooltip
                        title={`${OPTION_TO_MONTHS[token.option]} months lock`}
                        placement="top"
                    >
                    {token.isLockable ? (
                        <div>
                          <button
                              className={styles.unlockBtn}
                              onClick={() => handleUnlock(token, i)}
                              disabled={isUnlocking}
                          >
                            {(isUnlocking && unlockingIndex === i) ?  <span className={styles.loader} /> : "unlock"}
                          </button>
                        </div>

                    ) : (
                        <span className={styles.positionListItemText}>
                          {token.endSnapDateStringify}
                        </span>
                    )}
                    </Tooltip>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default React.memo(PositionTokens);
