import {LockSchemeTypes} from "../types/lockSchemeTypes";

export const LOCK_SCHEME: LockSchemeTypes = {
    3: '1x',
    6: '2.2x',
    12: '5x',
}

export const MONTHS_TO_OPTION: any = {
    3: 0,
    6: 1,
    12: 2,
}

export const OPTION_TO_MONTHS: any = {
    0: 3,
    1: 6,
    2: 12,
}
