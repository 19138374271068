import React, { useEffect, useRef } from 'react';
import styles from "./styles.module.scss"
// @ts-ignore
import Jazzicon from 'jazzicon';
import {useWeb3React} from "@web3-react/core";


const  Identicon = () => {
    const ref = useRef<HTMLDivElement>();

    const { account } = useWeb3React();

    useEffect(() => {
        if (account && ref.current) {
            ref.current.innerHTML = '';
            ref.current.appendChild(Jazzicon(22, parseInt(account.slice(2, 10), 22)));
        }
    }, [account]);

    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    return <div className={styles.identyicon} ref={ref as any} />;
}

export default Identicon