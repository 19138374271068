
import { ethers } from 'ethers';
import {configMainNet} from "../connectors/config";


export const useLpTokensContract =  async (address: string, abi: any, connector:any) => {
    if (connector) {
        const web3Provider = await connector.getProvider();
        const provider = new ethers.providers.Web3Provider(web3Provider)
        const signer = provider.getSigner()
        return new ethers.Contract(address, abi, signer)
    } else {
        const RPC = configMainNet.rpc;
        const provider = new ethers.providers.JsonRpcProvider(RPC, configMainNet.defaultChain);
        /*
          const signer = provider.getSigner();
        */
        return new ethers.Contract(address, abi, provider)
    }
}




