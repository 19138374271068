export const configMainNet = {
    defaultChain: 1234,
    chainId: 1234,
    supportedChainIds: [1234],
    name: "Step Network [MAINNET]",
    rpc: "https://rpc.step.network/",
    apiUrl: "",
    blockExplorerUrl: 'https://stepscan.io/',
    name: "FITFI",
    decimals: 18
};
