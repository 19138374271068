export const TOKENS_PAIRS = [
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0x4F4D77f1f8B12B183C78C53d6Dd9beaC805e1B09",
    },
    tokens: [
      {
        address: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
        chainId: 1234,
        decimals: 6,
        name: "USDC",
        coingeckoId: "usd-coin",
        logoUri:
          "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
        symbol: "USDC",
      },
      {
        address: "0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
        logoUri:
          "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707",
        chainId: 1234,
        decimals: 6,
        name: "USDT",
        coingeckoId: "tether",
        symbol: "USDT",
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0xEDf8A513C0e1fC89491E03946f908432D589327b",
    },
    tokens: [
      {
        address: "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
        logoUri:
          "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
        chainId: 1234,
        decimals: 18,
        name: "ETH",
        coingeckoId: "ethereum",
        symbol: "ETH",
      },
      {
        address: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
        chainId: 1234,
        decimals: 6,
        name: "USDC",
        coingeckoId: "usd-coin",
        logoUri:
          "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
        symbol: "USDC",
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0x9117372745dF764d2F1feB6372b358dE0F856b46",
    },
    tokens: [
      {
        address: "0xB44a9B6905aF7c801311e8F4E76932ee959c663C",
        logoUri:
          "https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744",
        chainId: 1234,
        decimals: 8,
        name: "WBTC",
        coingeckoId: "wrapped-bitcoin",
        symbol: "WBTC",
      },
      {
        address: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
        chainId: 1234,
        decimals: 6,
        name: "USDC",
        coingeckoId: "usd-coin",
        logoUri:
          "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
        symbol: "USDC",
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0x6e835eBF3a4c98f62b06f36823f6432A77a4291a",
    },
    tokens: [
      {
        address: "0xB44a9B6905aF7c801311e8F4E76932ee959c663C",
        logoUri:
          "https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744",
        chainId: 1234,
        decimals: 8,
        name: "WBTC",
        coingeckoId: "wrapped-bitcoin",
        symbol: "WBTC",
      },
      {
        address: "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
        logoUri:
          "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
        chainId: 1234,
        decimals: 18,
        name: "ETH",
        coingeckoId: "ethereum",
        symbol: "ETH",
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0xc07A896d2e57D60A66f04D4Ff893216E6432E18b",
    },
    tokens: [
      {
        address: "0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73",
        logoUri:
          "https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1644979850",
        chainId: 1234,
        decimals: 18,
        name: "BNB",
        coingeckoId: "binancecoin",
        symbol: "BNB",
      },
      {
        address: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
        chainId: 1234,
        decimals: 6,
        name: "USDC",
        coingeckoId: "usd-coin",
        logoUri:
          "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
        symbol: "USDC",
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0x669B2884e1e0941f97A44eCc29a8e2a39863a65E",
    },
    tokens: [
      {
        address: "0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73",
        logoUri:
          "https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1644979850",
        chainId: 1234,
        decimals: 18,
        name: "BNB",
        coingeckoId: "binancecoin",
        symbol: "BNB",
      },
      {
        address: "0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
        logoUri:
          "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707",
        chainId: 1234,
        decimals: 6,
        name: "USDT",
        coingeckoId: "tether",
        symbol: "USDT",
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0x8bA60723D569506A583F9c4C4E2525f01c1593EC",
    },
    tokens: [
      {
        address: "0xd6070ae98b8069de6B494332d1A1a81B6179D960",
        logoUri:
          "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
        chainId: 1234,
        decimals: 18,
        name: "AVAX",
        coingeckoId: "avalanche-2",
        symbol: "AVAX",
      },
      {
        address: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
        chainId: 1234,
        decimals: 6,
        name: "USDC",
        coingeckoId: "usd-coin",
        logoUri:
          "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
        symbol: "USDC",
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0xc8f6C6A51050e22fDc1b44D550a52464C6675B12",
    },
    tokens: [
      {
        address: "0xb58A9d5920AF6aC1a9522B0B10F55dF16686D1b6",
        logoUri:
            "https://s2.coinmarketcap.com/static/img/coins/64x64/19778.png",
        chainId: 1234,
        decimals: 18,
        name: "FITFI",
        coingeckoId: "step-app-fitfi",
        symbol: "FITFI",
      },
      {
        address: "0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
        logoUri:
            "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707",
        chainId: 1234,
        decimals: 6,
        name: "USDT",
        coingeckoId: "tether",
        symbol: "USDT",
      },
    ]
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0xF2Fb82D81240E641A49076Fe6879c128a485bb3f",
    },
    tokens: [
      {
        address: "0xb58A9d5920AF6aC1a9522B0B10F55dF16686D1b6",
        logoUri:
            "https://s2.coinmarketcap.com/static/img/coins/64x64/19778.png",
        chainId: 1234,
        decimals: 18,
        name: "FITFI",
        coingeckoId: "step-app-fitfi",
        symbol: "FITFI",
      },
      {
        address: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
        chainId: 1234,
        decimals: 6,
        name: "USDC",
        coingeckoId: "usd-coin",
        logoUri:
            "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
        symbol: "USDC",
      },
    ]
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0xF8D99ECF5b7c12dfc905c6a8A43910dbdB440eDD",
    },
    tokens: [
      {
        address: "0xe6801928061CDbE32AC5AD0634427E140EFd05F9",
        logoUri:
          "https://assets.coingecko.com/coins/images/15857/small/xcad.PNG?1622164789",
        chainId: 1234,
        decimals: 18,
        name: "XCAD",
        coingeckoId: "xcad-network",
        symbol: "XCAD",
      },
      {
        address: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
        chainId: 1234,
        decimals: 6,
        name: "USDC",
        coingeckoId: "usd-coin",
        logoUri:
          "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
        symbol: "USDC",
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0xa6b7A11A7d85d85f757d8D0A34c302269dF2b073",
    },
    tokens: [
      {
        address: "0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454",
        logoUri:
          "https://assets.coingecko.com/coins/images/13915/small/4.png?1612838831",
        chainId: 1234,
        decimals: 18,
        name: "DAO",
        coingeckoId: "dao-maker",
        symbol: "DAO",
      },
      {
        address: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
        chainId: 1234,
        decimals: 6,
        name: "USDC",
        coingeckoId: "usd-coin",
        logoUri:
          "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
        symbol: "USDC",
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: "SWAP-LP",
      name: "Swap-LP-Token",
      chainId: 1234,
      address: "0x601e0D177297C81D764B901f21e5cF102dc8c62C",
    },
    tokens: [
      {
        address: "0xf390830DF829cf22c53c8840554B98eafC5dCBc2",
        logoUri:
          "https://assets.coingecko.com/coins/images/24379/small/XANA_Logo_neon_pink.png?1658197388",
        chainId: 1234,
        decimals: 18,
        name: "XETA",
        coingeckoId: "xana",
        symbol: "XETA",
      },
      {
        address: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
        chainId: 1234,
        decimals: 6,
        name: "USDC",
        coingeckoId: "usd-coin",
        logoUri:
          "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
        symbol: "USDC",
      },
    ],
  },
];
