import React, { useEffect} from "react";
import styles from "./styles.module.scss";
import MainLogo from "../../assets/svgComponents/MainLogo";
import { isMobile } from "react-device-detect";

import RootModal from "../RootModalWrapper";
import ConnectWalletModal from "../Modals/ConnectWalletModal";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useActions } from "../../hooks/useActions";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../connectors/connectors";
import AccountDetails from "../AccountDetails";


const Header = () => {

  const { account, activate} = useWeb3React();

  const { isConnectWalletModalOpen } = useAppSelector(
      (state) => state.modalSlices
  );
  const { hideConnectModalVisible, showConnectModalVisible } = useActions();

  useEffect(() => {
    const provider = window.localStorage.getItem("provider");
    if (provider === 'injected' || provider === 'walletConnect' || (!isMobile && provider === 'coinbaseWallet') ) {
      // @ts-ignore
      activate(connectors[provider]);
    }
  }, []);

  return (
    <>
      <header className={styles.header}>
        <div className={styles.leftContainer}>
          <a href="https://step.exchange/" target="_blank">
            <MainLogo className={styles.mainLogo} />
          </a>
        </div>
        <div className={styles.headerWalletWrapper}>
           <a href="https://app.step.exchange/#/pool" target="_blank" className={styles.dexLink}>
            Step Dex <span>|</span> App
          </a>
          {account ? (
              <AccountDetails/>
          ) : (
            <button
              className={styles.headerWallet}
              onClick={showConnectModalVisible}
            >
              Connect wallet
            </button>
          )}
        </div>
        <RootModal
          isOpen={isConnectWalletModalOpen}
          onClose={hideConnectModalVisible}
        >
          <ConnectWalletModal />
        </RootModal>
      </header>
    </>
  );
};

export default Header;
