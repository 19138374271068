import React from 'react';
import styles from "./styles.module.scss";
import cx from "classnames";
import {useWindowSize} from "../../hooks/useWindowSize";


interface PropsTypes {
    approved: boolean | any,
    isTokenSelected: boolean,
    amount: number | string | undefined,
    handleCount: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handlePaste: (e: React.ClipboardEvent<HTMLInputElement>) => void,
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const AmountInput = ({approved, amount, handleCount, handlePaste, handleKeyDown, isTokenSelected}: PropsTypes) => {
    const { width: screenWidth = 861 } = useWindowSize();

    return (
        <div className={styles.inputWrapper}>
            <input
                type="text"
                placeholder={screenWidth < 500 ? "0.00" : "Enter amount"}
                className={cx(
                    styles.amountInput,
                    approved ? styles.amountInputApproved : ""
                )}
                value={amount}
                onChange={handleCount}
                onPaste={handlePaste}
                onKeyDown={handleKeyDown}
                disabled={!isTokenSelected}
            />
            {approved && (
                <span className={styles.approvedText}>
                <span className={styles.approvedTextMobile}>amount</span>{" "}
                    approved
              </span>
            )}
        </div>
    );
};

export default AmountInput;