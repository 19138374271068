import React, {useEffect, useMemo, useRef} from "react";
import { createPortal } from "react-dom";
import styles from "./styles.module.scss";
import {useAppDispatch} from "../../hooks/reduxHooks";

const modalRootElement = document.querySelector("#modal");

interface PropsTypes {
  isOpen: boolean,
  onClose: any,
  children: any,
}

const RootModal = ({ isOpen, onClose, children}: PropsTypes) => {
  const element = useMemo(() => document.createElement("div"), []);
  const dispatch = useAppDispatch();


  const handleClose = (): void => {
      dispatch(onClose())
  }

  useEffect(() => {
    if (isOpen) {
      modalRootElement?.appendChild(element);
      document.body.classList.add("onActiveModal")
      return () => {
        modalRootElement?.removeChild(element);
        document.body.classList.remove("onActiveModal")
      };
    }
  }, [isOpen]);

  if(isOpen) {
    return createPortal(
        <div className={styles.modalBg}>
          <div className={styles.modalContainer}>{children}
          <button className={styles.modalClose} onClick={handleClose}>Close</button>
          </div>
        </div>,
        element
    );
  }

  return null

};

export default RootModal;
