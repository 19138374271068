import { createSlice } from "@reduxjs/toolkit";
import { ModalStateTypes,  } from "../../types/InitialStateSliceTypes";

const initialState: ModalStateTypes = {
  isConnectWalletModalOpen: false,
};

const modalSlice = createSlice({
  name: "modalSlice",
  initialState,
  reducers: {
    showConnectModalVisible: (state) => {
      state.isConnectWalletModalOpen = true;
    },
    hideConnectModalVisible: (state) => {
      state.isConnectWalletModalOpen = false;
    },
  },

});

export const { showConnectModalVisible, hideConnectModalVisible  } = modalSlice.actions;
export default modalSlice.reducer;
