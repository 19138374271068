import React from "react";
import styles from "./styles.module.scss";
import cx from "classnames";

interface PropsTypes {
  disabled: boolean;
  text: string;
  isBlack?: boolean;
  onClickHandler?: any;
  isLoading?: boolean;
}

const CustomButton = ({
  disabled = false,
  text,
  isBlack = false,
  onClickHandler,
  isLoading = false,
}: PropsTypes) => {
  return (
    <button
      className={cx(styles.button, isBlack && styles.buttonBlack)}
      disabled={disabled}
      onClick={onClickHandler}
    >
      {isLoading ? (
        isBlack && !disabled ? (
          <span className={styles.loader} />
        ) : (
          <span className={styles.loaderBlack} />
        )
      ) : (
        text
      )}
    </button>
  );
};

export default CustomButton;
