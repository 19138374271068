import {configMainNet} from "../connectors/config"

export const addMainNetwork = async (connector: any) => {
    try {
            const web3Provider = await connector.getProvider();
            await web3Provider.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: `0x${Number(configMainNet.chainId).toString(16)}`,
                    chainName: configMainNet.name,
                    rpcUrls: [configMainNet.rpc],
                    blockExplorerUrls: [configMainNet.blockExplorerUrl],
                    nativeCurrency: {
                        name: configMainNet.name,
                        symbol: configMainNet.name,
                        decimals: configMainNet.decimals,
                    },
                }]
            })
    } catch (e) {
        console.log(e);
    }

}