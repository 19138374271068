import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import {configMainNet} from "./config";

const injected = new InjectedConnector({
    supportedChainIds: [configMainNet.chainId]
});


const walletconnect = new WalletConnectConnector({
    rpc: {
        [configMainNet.chainId]: configMainNet.rpc
    },
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    pollingInterval: 15000,
});

const walletlink = new WalletLinkConnector({
    rpc:  configMainNet.rpc,
    appName: "step-ex-lock-drop",

});

export const connectors = {
    injected: injected,
    walletConnect: walletconnect,
    coinbaseWallet: walletlink,
};
