import {
  handleSelectLpToken,
  handleIsTokenSelected,
  getMyLpTokens,
  handleIsLocked,
    handleIsUnlocked,
} from "../slices/selectLpTokenSlice";
import {
  showConnectModalVisible,
  hideConnectModalVisible,
} from "../slices/modalSlices";
import { getMyLpTokensThunk } from "../asyncThunks/getMyLpTokensThunk";

export const allActions = {
  handleSelectLpToken,
  showConnectModalVisible,
  hideConnectModalVisible,
  getMyLpTokensThunk,
  handleIsTokenSelected,
  getMyLpTokens,
  handleIsLocked,
  handleIsUnlocked,
};
