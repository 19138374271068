import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import TvlAmount from "./TvlAmount";

interface PropsTypes {
  snapshopDate: Date;
  isSnapshopDate: boolean
}

const FirstPhaseInfo = ({ snapshopDate, isSnapshopDate }: PropsTypes) => {
  const [daysLeft, setDaysLeft] = useState<number>(0);
  const [hoursLeft, setHoursLeft] = useState<number>(0);
  const [minutesLeft, setMinutesLeft] = useState<number>(0);
  const [secondsLeft, setSecondsLeft] = useState<number>(0);


  const getTimeLeft = (snapshopDate: Date) => {
    const nowDate = new Date();
    const timeGap = +snapshopDate - +nowDate;
    setDaysLeft(Math.floor(timeGap / 1000 / 60 / 60 / 24));
    setHoursLeft(Math.floor(timeGap / 1000 / 60 / 60) % 24);
    setMinutesLeft(Math.floor(timeGap / 1000 / 60) % 60);
    setSecondsLeft(Math.floor(timeGap / 1000) % 60);
  };

  useEffect(() => {
    const timesLeftInterval = setInterval(() => {
      getTimeLeft(snapshopDate);
    }, 1000);

    return () => {
      clearInterval(timesLeftInterval);
    };
  }, []);

  return (
    <div>
      {isSnapshopDate || secondsLeft < 0  ? <div className={styles.timeOver}>Phase #1 <br/> is over</div> : <div>
        Phase #1 ends in{" "}
        {<div className={styles.timesLeft}>
          {daysLeft}
          <span>:</span>
          {hoursLeft}
          <span>:</span>
          {minutesLeft}
          <span>:</span>
          {secondsLeft < 10 ? "0" + secondsLeft : secondsLeft}
        </div>}
      </div>}
      <TvlAmount/>
    </div>
  );
};

export default FirstPhaseInfo;
