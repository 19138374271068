import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {LpTokenTypes} from "../../types/InitialStateSliceTypes";
import {SELECTED_LP_TK_DEFAULT} from "../../constants/selectedLpDefault";

const initialState: LpTokenTypes = {
  selectedLpToken: SELECTED_LP_TK_DEFAULT,
  isTokenSelected: false,
  myLpTokens: [],
  isLocked: false,
  isUnlocked: false,
};

const LpTokens = createSlice({
  name: "selectLpToken",
  initialState,
  reducers: {
    handleSelectLpToken: (
      state,
      action: PayloadAction<typeof initialState.selectedLpToken>
    ): void => {
      state.selectedLpToken = action.payload;
    },
    getMyLpTokens: (state, action):void => {
      state.myLpTokens = action.payload;
    },
    handleIsTokenSelected: (state, action):void => {
      state.isTokenSelected = action.payload;
    },
    handleIsLocked: (state, action):void => {
      state.isLocked = !state.isLocked
    },
    handleIsUnlocked: (state, action):void => {
      state.isUnlocked= !state.isUnlocked
    },

  },
});

export const { handleSelectLpToken, getMyLpTokens, handleIsTokenSelected, handleIsLocked, handleIsUnlocked } = LpTokens.actions;
export default LpTokens.reducer;
