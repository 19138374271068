import React from 'react';

interface PropsTypes {
}

const CloseSvg = ({}: PropsTypes) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" stroke="currentColor" strokeWidth="2"
                 strokeLinecap="round" strokeLinejoin="round" className="sc-TtZnY fZVLOg">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
        </>
    );
};

export default CloseSvg;