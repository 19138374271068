import React from "react";
import styles from "./styles.module.scss";
import { monthsSliderMarks } from "../../constants/monthsSliderMarks";
import Slider from "@mui/material/Slider";

import cx from "classnames";
import {MarkTypes} from "../../types/markTypes";

interface PropsTypes {
    setMonths: (label: number) => void;
    months: number
}

const SliderTokens = ({ setMonths, months }: PropsTypes) => {

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        monthsSliderMarks.forEach(
            (mark: MarkTypes) => mark.value === newValue && setMonths(mark.label)
        );
    };


  return (
    <div className={cx(styles.sliderWrapper, months === 12 && styles.sliderWrapperEnd)}>
            <Slider
                min={1}
                max={3}
                defaultValue={1}
                step={null}
                valueLabelDisplay="off"
                marks={monthsSliderMarks}
                onChange={handleSliderChange}
                classes={{
                    root: styles.sliderRoot,
                    thumb: cx(styles.thumb, months === 3 ? styles.thumb1 : months === 12 ? styles.thumb3 : ''),
                    track: cx(styles.sliderTrack, months === 12 && styles.sliderTrackEnd),
                    rail: styles.sliderRail,
                    markLabel: cx( months === 12 ? styles.sliderLabelsEnd : styles.sliderLabels),
                    mark: styles.mark,
                    markLabelActive: styles.markLabelActive,
                    focusVisible: styles.sliderFocusVisible,
                }}
            />
    </div>
  );
};

export default SliderTokens;
