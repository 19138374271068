import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../src/components/Layout/index";
import { RouteNames } from "./constants/routeNames";
import MainPage from "../src/pages/MainPage/index";



function App() {
  return (
    <Routes>
      <Route path={RouteNames.MAIN_PAGE} element={<Layout />}>
        <Route index element={<MainPage />} />
      </Route>
    </Routes>
  );
}

export default App;
