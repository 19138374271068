import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { numWithCommas } from "../../utils/validationSchemes";
import CustomButton from "../../UI-core/CustomButton";
import { useFetchClaimAll } from "../../hooks/useFetchAllClaims";
import { useWeb3React } from "@web3-react/core";
import { fetchClaimInfo } from "../../api/fetchClaimInfo";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { checkDistributionDate } from "../../utils/checkDistributionDate";
import { ethers } from "ethers";
import { useLpTokensContract } from "../../hooks/useLpTokenContract";
import {
  DISTRIBUTOR_CONTRACT_ABI,
  DISTRIBUTOR_CONTRACT_ADDRESS,
} from "../../constants/distributorContract";

export const ClaimRewards = () => {
  const [spex, setSpex] = useState<number>(0);
  const [isClaimed, setIsClaimed] = useState<boolean>(false);

  const { account, connector } = useWeb3React();

  const distributorContract = useLpTokensContract(
    DISTRIBUTOR_CONTRACT_ADDRESS,
    DISTRIBUTOR_CONTRACT_ABI,
    connector
  );

  const [claim, isClaiming] = useFetchClaimAll(account, connector);
  const checkClaiming = async () => {
    const data = account && (await fetchClaimInfo(account));
    const currentContract = await distributorContract;
    const merkleIndex = await currentContract.merkleIndex();
    const isClaimed = await  currentContract?.isClaimed(merkleIndex, data.index)
    setIsClaimed(isClaimed)
  };

const handleClaimAll = async () => {
  // @ts-ignore
  await claim();
}

  useEffect(() => {
    account && connector && checkClaiming();
    const isSpexShown = checkDistributionDate();
    if (account && isSpexShown && connector) {
      fetchClaimInfo(account).then((res) => {
        const rewards = ethers.utils.formatUnits(res.amount.toString(), 18);
        res && setSpex(+rewards);
      });
    }
  }, [account, isClaiming]);

  if (account && spex > 0) {
    return (
      <div className={styles.claimWrapper}>
        <div className={styles.claimLabel}>
          claim rewards from your lockdrops
        </div>
        <Tooltip placement="top" title={`${spex} SPEX`}>
          <div className={styles.rewardsCount}>
            {numWithCommas(spex.toFixed(2).toString())} SPEX
          </div>
        </Tooltip>
        <div className={styles.btnWrapper}>
          <CustomButton
            disabled={isClaimed || Boolean(isClaiming)}
            text={isClaimed ? "Claimed" : "Claim rewards"}
            onClickHandler={handleClaimAll}
            isLoading={Boolean(isClaiming)}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ClaimRewards;
