import {useEffect, useState} from "react";
import { TOKENS_PAIRS } from "../constants/tokensPairs";
import { useTokenContract } from "./useTokenContract";
import { formatToDecimals } from "../utils/formatToDecimals";
import { CONTRACT_ADDRESS_VAULT } from "../constants/vaultContract";

import { getPriceInUsd } from "../utils/getPriceInUsd";
import { fethUSD } from "../utils/fetchUSD";
import {useWeb3React} from "@web3-react/core";


export const useTotalLocked = () => {
    const [listOfLpTkPairs, setListOfLpTkPairs] = useState<any>([]);
    const { connector } = useWeb3React();

    const threeMinDelay = 3 * 60 * 1000;

    const getLpTkList = async () => {
        const allLpTokens = await Promise.all(
            TOKENS_PAIRS?.map(async (item) => {
                const pairContract = await useTokenContract(item.liquidityToken.address, connector);
                const token0Contract = await useTokenContract(item.tokens[0].address, connector);
                const token1Contract = await useTokenContract(item.tokens[1].address, connector);

                const { token0InUsd, token1InUsd } = await fethUSD(
                    item.tokens[0].coingeckoId,
                    item.tokens[1].coingeckoId
                );
                const balanceAll: any = formatToDecimals(
                    await pairContract?.balanceOf(CONTRACT_ADDRESS_VAULT),
                    item.liquidityToken.decimals
                );


                const totalSupply: any = formatToDecimals(
                    await pairContract?.totalSupply(),
                    item.liquidityToken.decimals
                );
                const token0balance: any = formatToDecimals(
                    await token0Contract?.balanceOf(item.liquidityToken.address),
                    item.tokens[0].decimals
                );
                const token1balance: any = formatToDecimals(
                    await token1Contract?.balanceOf(item.liquidityToken.address),
                    item.tokens[1].decimals
                );

                const totalSupplyInUsd = getPriceInUsd(
                    balanceAll,
                    totalSupply,
                    token0balance,
                    token0InUsd,
                    token1balance,
                    token1InUsd
                );



                return {
                    balanceAll,
                    totalSupplyInUsd:
                        (!token0InUsd || !token1InUsd ) ? "" : (totalSupplyInUsd),
                };
            })
        );
        setListOfLpTkPairs(allLpTokens);
    };

    const tvl = listOfLpTkPairs?.reduce((accumulator: any, current: any) => accumulator + current.totalSupplyInUsd, 0);
    useEffect(() => {
        getLpTkList();
        setInterval( async () => {
                await getLpTkList()
        }, threeMinDelay);
    }, [connector]);

    return [tvl, listOfLpTkPairs];
};
