import axios from "axios";

export const fethUSD = async (toke0id: string | undefined, token1id: string | undefined) => {

    try {
        if (toke0id && token1id) {
            const resToken0 = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${toke0id}&vs_currencies=usd`);
            const token0InUsd = resToken0?.data[toke0id].usd;

            const resToken1 = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${token1id}&vs_currencies=usd`);
            const token1InUsd = resToken1?.data[token1id].usd;

            return {token0InUsd, token1InUsd}
        } else {
            return {token0InUsd: 0, token1InUsd: 0}
        }

    } catch (e) {
        return {token0InUsd: 0, token1InUsd: 0}
    }


};