import { OPTION_TO_MONTHS } from "../constants/projectCompilers";
import { useSnapShotDate } from "../hooks/useSnapShotDate";

interface returnType {
  endSnapDate: Date;
  endSnapDateStringify: string;
}

export const getEndDate = async (option: number, connector: any): Promise<returnType> => {
  const snapDate = await useSnapShotDate(connector);
  const months = OPTION_TO_MONTHS[option];
  snapDate.setMonth(snapDate.getMonth() + months);

  return {
    endSnapDate: snapDate,
    endSnapDateStringify: `${
      snapDate.getDate() < 10 ? "0" + snapDate.getDate() : snapDate.getDate()
    }.${
      +snapDate.getMonth() + 1 < 10
        ? "0" + (+snapDate.getMonth() + 1)
        : +snapDate.getMonth() + 1
    }.${snapDate.getFullYear()}`,
  };
};
