import React from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import expandIcon from "../../assets/icons/Vector.svg";
import {MyLpTokens} from "../../types/InitialStateSliceTypes";

interface PropsTypes {
  isTokenSelected: boolean;
  account: string | null | undefined;
  expanded: boolean;
  setExpanded: (prev: any) => void;
  selectedLpToken: MyLpTokens;
}

const ChoosePairBlock = ({
  isTokenSelected,
  account,
  expanded,
  setExpanded,
  selectedLpToken,
}: PropsTypes) => {
  return (
      <div onClick={() => account && setExpanded((prev: any) => !prev)}>
        <div
            className={cx(
                isTokenSelected ? styles.chooseToken : styles.chooseTokenWide
            )}
        >
          {isTokenSelected ? (
              <div className={styles.tokenItemInfo}>
                <div className={styles.tokenLogos}>
                  <img
                      src={selectedLpToken.tokenFrom.logoUri}
                      alt="token logo"
                      className={styles.tokenLogoImgFirst}
                  />
                  <img
                      src={selectedLpToken.tokenTo.logoUri}
                      alt="token logo"
                      className={styles.tokenLogoImgSecond}
                  />
                </div>
                <span className={styles.tokenItemCurrency}>
            {selectedLpToken.tokenFrom.name}:{selectedLpToken.tokenTo.name}
          </span>
              </div>
          ) : (
              <span>Choose LP token</span>
          )}
          <img
              src={expandIcon}
              className={cx(
                  styles.expandIcon,
                  expanded && styles.expandIconActive,
                  isTokenSelected && styles.expandIconActiveBottom
              )}
              alt="Expand icon"
          />
        </div>
      </div>

  );
};

export default ChoosePairBlock;
