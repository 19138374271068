
import { ethers } from 'ethers';
import {ERC_20_ABI} from "../constants/erc20ABI";
import {configMainNet} from "../connectors/config";


export const useTokenContract = async (address: string, connector: any) => {
    // @ts-ignore
    if (connector) {
        const web3Provider = await connector.getProvider();
        const provider = new ethers.providers.Web3Provider(web3Provider);
        const signer = provider.getSigner()
        return new ethers.Contract(address, ERC_20_ABI, signer);
    } else {
        const RPC = configMainNet.rpc;
        const provider = new ethers.providers.JsonRpcProvider(RPC, configMainNet.defaultChain);
/*
        const signer = provider.getSigner();
*/
        return new ethers.Contract(address, ERC_20_ABI, provider);
    }

}

