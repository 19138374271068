import React from "react";
import { numWithCommas } from "../../utils/validationSchemes";
import styles from "./styles.module.scss";
import {useTotalLocked} from "../../hooks/useTotalLocked";

const TvlAmount = () => {
  const [tvl] = useTotalLocked();
  return (
    <div className={styles.tvlWrapper}>
      Lockdrop tvl is{" "}
      {tvl ? "$" + numWithCommas(Math.round(tvl).toString()) : <span className={styles.tvlLoading}>loading...</span>}
    </div>
  );
};

export default React.memo(TvlAmount);
