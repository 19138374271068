import React from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { MONTH_NAMES } from "../../constants/dates";
import SliderTokens from "./SliderTokens";

interface PropsTypes {
  months: number;
  lockDate: Date;
  setMonths: (month: number) => void;
}

const ChooseTermBlock = ({ months, lockDate, setMonths }: PropsTypes) => {
  const lockDateEnd = new Date(lockDate);
  return (
    <div className={cx(styles.myTokensWrapper, styles.chooseTermWrapper)}>
      <div className={styles.dateInfo}>
        <div className={styles.monthInfoCount}>
          <h4>Lock and date</h4>
          <span className={styles.months}>{months} months</span>
        </div>
        <span className={styles.dateInfoCount}>
          Your lock end at <br />
          {lockDateEnd.toString().length > 15 && (
            <span>
              {MONTH_NAMES[lockDateEnd.getMonth()]} {lockDateEnd.getDate()},{" "}
              {lockDateEnd.getFullYear()}
            </span>
          )}
        </span>
      </div>
      <SliderTokens setMonths={setMonths} months={months} />
    </div>
  );
};

export default ChooseTermBlock;
