import React from "react";
import Header from "../Header";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import styles from "./styles.module.scss";

const Layout = () => {
  return (
    <>
      <Header />
      <div className={styles.mainContainer}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
