import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { numWithCommas, removeLetters } from "../../utils/validationSchemes";
import { checkDup } from "../../utils/checkDup";
import { useAppSelector } from "../../hooks/reduxHooks";
import {
  LOCK_SCHEME,
  MONTHS_TO_OPTION,
} from "../../constants/projectCompilers";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import {
  CONTRACT_ABI_VAULT,
  CONTRACT_ADDRESS_VAULT,
} from "../../constants/vaultContract";
import { useApproveCallback } from "../../hooks/useApproveCallback";
import { useLpTokensContract } from "../../hooks/useLpTokenContract";
import { useActions } from "../../hooks/useActions";
import { SELECTED_LP_TK_DEFAULT } from "../../constants/selectedLpDefault";
import MyLpTokensList from "../MyLpTokensList";
import ChoosePairBlock from "./ChoosePairBlock";
import AmountInput from "./AmountInput";
import ChooseTermBlock from "./ChooseTermBlock";
import ActionButtons from "./ActionButtons";
import {useSnapShotDate} from "../../hooks/useSnapShotDate";
import CustomButton from "../../UI-core/CustomButton";

interface PropsTypes {
  isSnapshopDate: boolean
}

const ChooseLpToken = ({isSnapshopDate}:PropsTypes) => {
  const [months, setMonths] = useState<number>(3);
  const [lockDate, setLockDate] = useState<any>('');
  const [amount, setAmount] = useState<string>("");
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const { account, connector } = useWeb3React();
  const accordionRef = useRef<any>();
  const selectedLpToken = useAppSelector(
    (state) => state.selectLpTokenReducer.selectedLpToken
  );
  const isTokenSelected = useAppSelector(
    (state) => state.selectLpTokenReducer.isTokenSelected
  );
  const isLocked = useAppSelector(
    (state) => state.selectLpTokenReducer.isLocked
  );
  const {
    getMyLpTokensThunk,
    handleSelectLpToken,
    handleIsTokenSelected,
    handleIsLocked,
  } = useActions();

  useEffect(() => {
    (async () => {
      const snapDate = await useSnapShotDate(connector);
      const lockDate = snapDate.setMonth(snapDate.getMonth() + months);
      setLockDate(lockDate)
    })()
  }, [months]);



  const handleCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const onlyNum = removeLetters(e.target.value).replace(/,+/g, "");
    if (+onlyNum > selectedLpToken.amount) {
      setAmount(numWithCommas(selectedLpToken.amount.toString()));
    } else {
      setAmount(numWithCommas(onlyNum));
    }
  };

  const setMaxValue = () => {
    if (selectedLpToken.amount) {
      setAmount(numWithCommas(selectedLpToken.amount.toString()));
    } else {
      return null;
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const paste = (e.clipboardData || (window as any).clipboardData)
      ?.getData("text")
      .replace(/,+/g, "");
    if (/[^a-zA-Z,]/.test(paste) && !checkDup(paste, ".")) {
      setAmount(numWithCommas(paste));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ".") {
      amount.includes(".") && e.preventDefault();
    }
  };

  const closeOutside = (e: any) => {
    if (!e.path.includes(accordionRef.current)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    if (expanded) {
      document.body.addEventListener("mousedown", closeOutside);
    }
    return () => document.body.removeEventListener("mousedown", closeOutside);
  }, [expanded]);

  const [approved, approve, isLoading] = useApproveCallback(
    amount.replace(/,+/g, "")
  );

  const contract = useLpTokensContract(
    CONTRACT_ADDRESS_VAULT,
    CONTRACT_ABI_VAULT,
      connector
  );

  useEffect(() => {
    setAmount("");
  }, [selectedLpToken]);

  const handleCreateLock = async () => {
    const currentContract = await contract;
    try {
      setIsApproving(true);
      const value = ethers.utils.parseUnits(amount, 18);
      await currentContract
          .deposit(selectedLpToken.contractAddress, value, MONTHS_TO_OPTION[months])
          .then(() => {
            setTimeout(() => {
              setIsApproving(false);
              setAmount("");
              account && getMyLpTokensThunk(account);
              handleSelectLpToken(SELECTED_LP_TK_DEFAULT);
              handleIsTokenSelected(false);
              handleIsLocked(!isLocked);
            }, 20000);
          })
          .catch(() => {
            setIsApproving(false);
          });
    }
    catch (e) {
      setIsApproving(false);
    }
  };

  return (
    <section className={styles.container}>
      <h1 className={styles.chooseTokensLabel}>New lock</h1>
      <div className={styles.myTokensWrapper}>
        <div className={styles.myTokensInfo}>
          <h4>Your LP Token</h4>
          <div className={styles.myTokensAmount}>
            <span style={{ cursor: "pointer" }} onClick={setMaxValue}>
              Available
            </span>
            {isTokenSelected && (
              <span>{numWithCommas(selectedLpToken.amount.toString())}</span>
            )}
          </div>
        </div>
        <div className={styles.chooseWrapper} ref={accordionRef}>
          <ChoosePairBlock
            isTokenSelected={isTokenSelected}
            account={account}
            expanded={expanded}
            setExpanded={setExpanded}
            selectedLpToken={selectedLpToken}
          />
          <AmountInput
            amount={amount}
            isTokenSelected={isTokenSelected}
            handleCount={handleCount}
            handlePaste={handlePaste}
            handleKeyDown={handleKeyDown}
            approved={approved}
          />
          <div
            className={cx(
              styles.accordionContent,
              expanded && styles.accordionContentActive
            )}
          >
            <MyLpTokensList setExpanded={setExpanded} />
          </div>
        </div>
      </div>
      <ChooseTermBlock
        lockDate={lockDate}
        months={months}
        setMonths={setMonths}
      />
      <div className={styles.profitContainer}>
        <div
          className={cx(
            styles.profitWrapper,
            isTokenSelected && styles.profitWrapperWide
          )}
        >
          <div className={styles.profitItem}>
            <span className={styles.profitItemTitle}>Multiplier</span>{" "}
            <span>{LOCK_SCHEME[months]}</span>
          </div>
        </div>
        {isSnapshopDate ? <div className={styles.buttonsWrapper}><CustomButton disabled={true} text={"lock is disabled"} isBlack={true} onClickHandler={() => {}}/></div> : <ActionButtons
            approved={approved}
            isTokenSelected={isTokenSelected}
            approve={approve}
            isLoading={isLoading}
            isApproving={isApproving}
            handleCreateLock={handleCreateLock}
            amount={amount}
        />}
      </div>
    </section>
  );
};

export default ChooseLpToken;
