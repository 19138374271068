import React from "react";
import styles from "./styles.module.scss";
import CustomButton from "../../UI-core/CustomButton";

interface PropsTypes {
  approved: boolean | (() => Promise<void>),
  isApproving: boolean;
  handleCreateLock: () => void;
  isTokenSelected: boolean;
  approve: boolean | (() => Promise<void>),
  isLoading: boolean | (() => Promise<void>),
  amount: string,
}

const ActionButtons = ({
  approved,
  isApproving,
  handleCreateLock,
  isTokenSelected,
  amount,
  approve,
  isLoading,
}: PropsTypes) => {
  return (
    <>
      {approved ? (
        <div className={styles.buttonsWrapper}>
          <div className={styles.approvedButtonWrapper}>
            <CustomButton
              isBlack={true}
              disabled={isApproving}
              text="Create lock"
              isLoading={isApproving}
              onClickHandler={handleCreateLock}
            />
          </div>
        </div>
      ) : (
        <div className={styles.buttonWrapper}>
          <CustomButton
            disabled={
              !isTokenSelected ||
              !amount ||
              Boolean(isLoading) ||
              +amount.replace(/,+/g, "") === 0
            }
            text={
              !isTokenSelected
                ? "Choose lp token"
                : !amount || +amount.replace(/,+/g, "") === 0
                ? "Enter LP amount"
                : "approve"
            }
            isBlack={true}
            onClickHandler={approve}
            isLoading={Boolean(isLoading)}
          />
        </div>
      )}
    </>
  );
};

export default ActionButtons;
