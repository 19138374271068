import axios from "axios";

export const fetchClaimInfo = async (account: string) => {
    try{
        const result = await axios.get(
            `https://dev.api.stepex.finance/v1/lockdrop/claim/${account}`
        );
        return result.data
    } catch (e) {
            return null
    }
}


