import { useLpTokensContract } from "./useLpTokenContract";
import {
  CONTRACT_ABI_VAULT,
  CONTRACT_ADDRESS_VAULT,
} from "../constants/vaultContract";
import { ethers } from "ethers";

export const useSnapShotDate = async (connector: any): Promise<Date> => {
  const vaultContract = await useLpTokensContract(
    CONTRACT_ADDRESS_VAULT,
    CONTRACT_ABI_VAULT,
      connector
  );
  const responseDate = await vaultContract.snapshotTimestamp();
  return new Date(+ethers.utils.formatUnits(responseDate, 0) * 1000);
};
