import {MarkTypes} from "../types/markTypes";

export const monthsSliderMarks: MarkTypes[] = [
    {
        value: 1,
        label: 3,
    },
    {
        value: 2,
        label: 6,
    },
    {
        value: 3,
        label: 12,
    },
];