import React from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import styles from "./styles.module.scss";
import metamaskIcon from "../../../assets/icons/metamask.png";
import cx from "classnames";
import coinbaseIcon from "../../../assets/icons/coinbaseWalletIcon.svg";
import walletConnectIcon from "../../../assets/icons/walletConnectIcon.ico";
import { connectors } from "../../../connectors/connectors";
import { useActions } from "../../../hooks/useActions";
import { isMobile } from "react-device-detect";
import { addMainNetwork } from "../../../utils/addMainNetwork";
import { SUPPORTED_WALLETS } from "../../../constants/supportedWallets";

const ConnectWalletModal = () => {
  const { activate, connector, account } = useWeb3React();
  const { hideConnectModalVisible } = useActions();
  const tryActivation = async (connector: any, walletConnector: any) => {
    try {
      const metamaskDeepLink = "https://metamask.app.link/dapp/";
      if (
        isMobile &&
        walletConnector.name?.toLowerCase() === "metamask" &&
        !window.ethereum
      ) {
        window.open(metamaskDeepLink + window.location.host);
        return;
      }

      let conn =
        typeof connector === "function" ? await connector() : connector;

      Object.keys(SUPPORTED_WALLETS).map((key) => {
        if (connector === SUPPORTED_WALLETS[key].connector) {
          return SUPPORTED_WALLETS[key].name;
        }
        return true;
      });

      conn &&
        activate(conn, undefined, true).then(() => {
          if (walletConnector.name === "WalletConnect") {
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }
        }).catch((error) => {
          if (error instanceof UnsupportedChainIdError) {
            activate(conn); // a little janky...can't use setError because the connector isn't set
            if (walletConnector.name === "WalletConnect") {
              setTimeout(() => {
                window.location.reload()
              }, 1000)
            }
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const setProvider = (type: any) => {
    window.localStorage.setItem("provider", type);
  };
  return (
    <div className={styles.connectWrapper}>
      <div className={styles.modalLabel}>Connect wallet</div>
      <div
        className={cx(
          styles.walletItem,
          connectors.injected === connector && account && styles.walletItemActive
        )}
        onClick={async () => {
          if ((connectors.injected === connector) && account) return;
          await tryActivation(
            connectors.injected,
            SUPPORTED_WALLETS["METAMASK"]
          );
          connector && await addMainNetwork(connector);
          setProvider("injected");
          hideConnectModalVisible();
        }}
      >
        <img src={metamaskIcon} alt="" className={styles.walletItemImg} />
        <span className={styles.walletItemText}>Metamask</span>
      </div>
      <div
        className={cx(
          styles.walletItem,
          connectors.walletConnect === connector && account && styles.walletItemActive
        )}
        onClick={async () => {
          if ((connectors.walletConnect === connector) && account) return;
          await tryActivation(
            connectors.walletConnect,
            SUPPORTED_WALLETS["WALLET_CONNECT"]
          );
          connector && await addMainNetwork(connector);
          setProvider("walletConnect");
          hideConnectModalVisible();

        }}
      >
        <img src={walletConnectIcon} alt="" className={styles.walletItemImg} />
        <span className={styles.walletItemText}>Wallet connect</span>
      </div>
      <div
        className={cx(
          styles.walletItem,
          connectors.coinbaseWallet === connector && account && styles.walletItemActive
        )}
        onClick={async () => {
          await tryActivation(
            connectors.coinbaseWallet,
            SUPPORTED_WALLETS["WALLET_LINK"]
          );
          connector && await addMainNetwork(connector);
          setProvider("coinbaseWallet");
          hideConnectModalVisible();
        }}
      >
        <img src={coinbaseIcon} alt="" className={styles.walletItemImg} />
        <span className={styles.walletItemText}>Coinbase wallet</span>
      </div>
    </div>
  );
};

export default React.memo(ConnectWalletModal);
