import React from "react";
import styles from "./styles.module.scss";
import cx from "classnames"
import { SUPPORTED_WALLETS } from "../../constants/supportedWallets";
import { connectors } from "../../connectors/connectors";
import { useWeb3React } from "@web3-react/core";
import Identicon from "./Identiicon";
import CoinbaseWalletIcon from "../../assets/icons/coinbaseWalletIcon.svg";
import WalletConnectIcon from "../../assets/icons/walletConnectIcon.svg";
import { truncateAddress } from "../../utils/shortenAddress";
import { useActions } from "../../hooks/useActions";
import { SELECTED_LP_TK_DEFAULT } from "../../constants/selectedLpDefault";
import { Popover } from "@mui/material";
import CloseSvg from "../../assets/svgComponents/closeSvg";
import CopySvg from "../../assets/svgComponents/copySvg";
import ShareSvg from "../../assets/svgComponents/shareSvg";
import useCopyClipboard from "../../hooks/useCopyClipboard";
import { getEtherscanLink } from "utils/getEtherscanLink";

interface PropsTypes {}

const AccountDetails = ({}: PropsTypes) => {
  const { account, deactivate, connector, chainId } = useWeb3React();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const { handleSelectLpToken, handleIsTokenSelected } = useActions();
  const [isCopied, setCopied] = useCopyClipboard(2000);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { showConnectModalVisible } = useActions();

  const refreshState = () => {
    // @ts-ignore
    window.localStorage.setItem("provider", undefined);
  };

  const disconnect = () => {
    refreshState();
    deactivate();
    handleClose();
    handleSelectLpToken(SELECTED_LP_TK_DEFAULT);
    handleIsTokenSelected(false);
  };

  const handleChangeWallet = () => {
    showConnectModalVisible()
    handleClose();
  }

  // @ts-ignore
  const { ethereum } = window;
  const isMetaMask = !!(ethereum && ethereum.isMetaMask);

  const wallet = Object.keys(SUPPORTED_WALLETS)
    .filter(
      (k) =>
        SUPPORTED_WALLETS[k].connector === connector &&
        (connector !== connectors.injected || isMetaMask === (k === "METAMASK"))
    )
    .map((k) => SUPPORTED_WALLETS[k])[0];

  const getStatusIcon = () => {
    if (connector === connectors.injected) {
      return (
        <div className={styles.iconWrapper}>
          <Identicon />
        </div>
      );
    } else if (connector === connectors.walletConnect) {
      return (
        <div className={styles.iconWrapper}>
          <img src={WalletConnectIcon} alt={"wallet connect logo"} />
        </div>
      );
    } else if (connector === connectors.coinbaseWallet) {
      return (
        <div className={styles.iconWrapper}>
          <img src={CoinbaseWalletIcon} alt={"wallet connect logo"} />
        </div>
      );
    }
  };

  return (
    <>
      <div className={styles.accountDetailsWrapper} onClick={handleClick}>
        <div>{getStatusIcon()}</div>
        <div className={styles.accountInfo}>
          <div className={styles.accountInfoWallet}>
            {account && truncateAddress(account)}
          </div>
          <div className={styles.accountNetwork}>Step Network</div>
        </div>
        {isCopied && open && <div className={styles.copiedText}>Copied to clipboard</div>}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{ paper: styles.paper }}
        disableScrollLock
      >
        <div className={styles.paperHeader}>
          <span>Wallet</span>
          <div className={styles.closeIconWrapper} onClick={handleClose}>
            <CloseSvg />
          </div>
        </div>
        <div className={styles.userInfoWrapper}>
          <div className={styles.networkInfo}>
            <span>Connected via {wallet.name}</span>
            <img
              src={require(`../../assets/icons/${wallet?.iconName}`)}
              alt=""
              className={styles.networkImg}
            />
          </div>
          <div className={styles.accountActionWrapper}>
            <div className={styles.accountActionInfo}>
              <div className={styles.actionUserIcon}>{getStatusIcon()}</div>{" "}
              <div className={cx(styles.accountInfoWallet, styles.accountInfoWalletAction)}>
                {account && truncateAddress(account)}
              </div>
              { account && <div className={styles.copyIcon} onClick={() => setCopied(account)}>
                <CopySvg/>
              </div>}
              {chainId && account && <a className={styles.copyIcon} href={getEtherscanLink(chainId, account, 'address')} target="_blank">
                <ShareSvg/>
              </a>}
            </div>
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <button className={styles.walletBtn} onClick={handleChangeWallet}>change</button>
          <button className={cx(styles.walletBtn, styles.walletBtnBlack)} onClick={disconnect}>disconnect</button>
        </div>
      </Popover>
    </>
  );
};

export default AccountDetails;
