import axios from "axios";

export const fetchRewardsByPool = async (account: string) => {
    try{
        const result = await axios.get(
            `https://dev.api.stepex.finance/v1/lockdrop/reward/${account}`
        );
        return result.data
    } catch (e) {
        return null
    }
}


