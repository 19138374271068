import { createAsyncThunk } from "@reduxjs/toolkit";
import { TOKENS_PAIRS } from "../../constants/tokensPairs";
import { ethers } from "ethers";
import { useTokenContract } from "../../hooks/useTokenContract";
import { getMyLpTokens } from "../slices/selectLpTokenSlice";

export const getMyLpTokensThunk = createAsyncThunk(
  "getMyLpTokens",
  async (args: any, { dispatch }) => {
      const {address, connector} = args;
    const lpTokens = await Promise.all(
      TOKENS_PAIRS.map(async (item) => {
          const currentContract = await useTokenContract(item.liquidityToken.address, connector);
          return {
        amount: ethers.utils.formatUnits(
          await currentContract?.balanceOf(
            address
          ),
          item.liquidityToken.decimals
        ),
        contractAddress: item.liquidityToken.address,
        tokenFrom: {
          name: item.tokens[0].name,
          address: item.tokens[0].address,
          decimals: item.tokens[0].decimals,
          logoUri: item.tokens[0].logoUri,
          coingeckoId: item.tokens[0].coingeckoId,
        },
        tokenTo: {
          name: item.tokens[1].name,
          address: item.tokens[1].address,
          decimals: item.tokens[1].decimals,
          logoUri: item.tokens[1].logoUri,
          coingeckoId: item.tokens[1].coingeckoId,
        },
      }})
    );
    dispatch(getMyLpTokens(lpTokens));
  }
);
