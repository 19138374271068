import React from "react";
import styles from "./styles.module.scss";
import { useActions } from "../../hooks/useActions";
import { useAllLpTokensList } from "../../hooks/useAllLpTokensList";
import { useWeb3React } from "@web3-react/core";
import cx from "classnames";
import { Tooltip } from "@mui/material";
import { numWithCommas } from "../../utils/validationSchemes";

interface PropsTypes {
  setExpanded: any,
}

const MyLpTokensList = ({setExpanded}: PropsTypes) => {
  const { handleSelectLpToken, handleIsTokenSelected } = useActions();
  const [listOfLpTkPairs, tvl] = useAllLpTokensList();
  const { account } = useWeb3React();
  const handleLock = (token: any) => {
    if (token.amount <= 0 || !account) {
      return
    } else {
      const selectedToken = {
        amount: token.amount,
        contractAddress: token.contractAddress,
        tokenFrom: token.tokenFrom,
        tokenTo: token.tokenTo,
      };
      handleSelectLpToken(selectedToken);
      handleIsTokenSelected(true);
      setExpanded(false);
    }
  };
  return (
      <div className={styles.tokensWrapper}>
        <div className={styles.tokensTabs}>
          <div className={styles.tokensPair}>
            <span className={styles.tokensTabText}>Pair</span>
          </div>
          <div className={styles.tokensSupply}>
            <span className={styles.tokensTabText}>Total LP locked</span>
          </div>
          <div className={styles.tokensLiquidity}>
            <span className={styles.tokensTabText}>my lp liquidity</span>
          </div>
          <div className={styles.tokensLock} />
        </div>
        {listOfLpTkPairs.map((token: any, i: React.Key) => (
          <div className={cx(styles.tokenItem, (token.amount <= 0 || !account) &&  styles.tokenItemDisabled)} key={i} onClick={() => handleLock(token)}>
            <div className={styles.tokensPair}>
              <div className={styles.tokenLogos}>
                <img
                  src={token.tokenFrom.logoUri}
                  className={styles.tokenLogoImgFirst}
                  alt="token logo"
                />
                <img
                  src={token.tokenTo.logoUri}
                  className={styles.tokenLogoImgSecond}
                  alt="token logo"
                />
              </div>
              <span className={cx(styles.tokenItemText, styles.tokenLogoText)}>
                {token.tokenFrom.name}:{token.tokenTo.name}
              </span>
            </div>
            <div className={styles.tokensSupply}>
              <div className={cx(styles.tokenItemText, styles.supplyWrapper)}>
                <Tooltip
                  title={numWithCommas(token.balanceAll.toString())}
                  placement={"top"}
                >
                  {<span className={styles.supplyText}>
                    {numWithCommas(
                          token.balanceAll.toString()
                      )}
                  </span>}
                </Tooltip>
                  <>
                    <span className={styles.space}/>(
                    <Tooltip
                      title={
                        "$" + numWithCommas(token.totalSupplyInUsd.toString())
                      }
                      placement={"top"}
                    >
                      <span  className={cx(styles.supplyText, styles.supplyTextUsd)}>
                         { "$" +
                             numWithCommas(
                                Number(token.totalSupplyInUsd).toFixed(2).toString()
                             )}
                      </span>
                    </Tooltip>
                    )
                  </>
              </div>
            </div>
            <div className={styles.tokensLiquidity}>
              <div
                className={cx(styles.tokenItemText, styles.liquidityWrapper)}
              >
                {account ? (
                  <>
                    <Tooltip
                      title={numWithCommas(token?.amount?.toString())}
                      placement={"top"}
                    >
                      <div className={styles.liquidityText}>
                        {numWithCommas(
                                token.amount.toString()
                            )}
                      </div>
                    </Tooltip>
                      <>
                        <span className={styles.space}/>
                        (
                        <Tooltip
                          title={
                            "$" + numWithCommas(token.amountInUsd.toString())
                          }
                          placement={"top"}
                        >
                          <div className={cx(styles.liquidityText, styles.liquidityTextUsd)}>
                            {"$" +
                                Number(token.amountInUsd).toFixed(2).toString()}
                          </div>
                        </Tooltip>
                        )
                      </>
                  </>
                ) : (
                  "no wallet"
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
  );
};

export default MyLpTokensList;
