export const removeLetters = (word: string): string =>
  word.replace(/[^0-9.]/, "");
export const numWithCommas = (word: string): string => {
  if (word.includes(".")) {
    return word
      .split(".")
      .map((subWord, i) =>
        i === 0 ? subWord.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : subWord
      )
      .join(".");
  } else {
    return word.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
