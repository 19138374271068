export const SELECTED_LP_TK_DEFAULT = {
  amount: 0,
  contractAddress: "0x4F4D77f1f8B12B183C78C53d6Dd9beaC805e1B09",
  tokenFrom: {
    name: "",
    address: "",
    decimals: 0,
    coingeckoId: '',
    logoUri: ''
  },
  tokenTo: {
    name: "",
    address: "",
    decimals: 0,
    coingeckoId: '',
    logoUri: ''
  },
};
